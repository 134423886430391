module.exports = {
  title: "",
  // 加载远程图
  loadRemotePic: true,
  tracking: {
    url: process.env.VUE_APP_BASE_API + "/osa/logAgent/save",
    scene: {
      //页面进入前
      before: {
        // 路由名称
        turntable: {
          userVisit: 6,
        },
      },
      //页面进入后
      after: {},
      //返回页面
      back: {},
      //页面所有接口请求后埋点
      nextRequest: {
        turntable: {
          userVisit: 2,
        },
      },
    },
  },
};
